import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";

import * as constants from '../../constantsuniodonto';
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import {getStorageUserRole} from "../../services/UserService";

const proposalService = require('../../services/ProposalService');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function ProposalsIndex() {
  const [proposals, setProposals] = React.useState([]);
  const [getProposalStatus, setGetProposalStatus] = React.useState('');
  const [getProposalType, setGetProposalType] = React.useState('');
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [proposalCount, setProposalCount] = React.useState(0);
  const [dataInicial, setDataInicial] = React.useState('');
  const [dataFinal, setDataFinal] = React.useState('');

  let history = useHistory();
  const { search } = useLocation();

  const formatDate = (date) => {
    let dt = new Date(date);
    return dt.getDate()+'/'+(dt.getMonth()+1)+'/'+dt.getFullYear()
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(search);
    let proposalStatus = urlParams.get('status');
    let proposalType = urlParams.get('type');

    if (!proposalType && !proposalStatus) {
      setGetProposalStatus('Rascunho');
      setGetProposalType('Pessoal');
      history.push({
        pathname: '/admin/proposals',
        search: `?status=Rascunho&type=Pessoal`
      });
    } else if (proposalType && !proposalStatus) {
      setGetProposalStatus('Rascunho');
      setGetProposalType(proposalType);
      history.push({
        pathname: '/admin/proposals',
        search: `?status=Rascunho&type=${proposalType}`
      });
    } else if (!proposalType && proposalStatus) {
      setGetProposalStatus(proposalStatus);
      setGetProposalType('Pessoal');
      history.push({
        pathname: '/admin/proposals',
        search: `?status=${proposalStatus}&type=Pessoal`
      });
    } else if (proposalType && proposalStatus) {
      setGetProposalStatus(proposalStatus);
      setGetProposalType(proposalType);
      history.push({
        pathname: '/admin/proposals',
        search: `?status=${proposalStatus}&type=${proposalType}`
      });
    }
  }, []);
  
  React.useEffect(() => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(search);
    let proposalType = urlParams.get('type');
    let proposalStatus = urlParams.get('status');

    if (!proposalStatus) {
      proposalStatus = 'Rascunho';
    }

    if (!proposalType) {
      proposalType = 'Pessoal';
    }

    let data;

    if (dataInicial && dataFinal) {
      data = {proposalType, proposalStatus, dataInicial, dataFinal}
    } else {
      data = {proposalType, proposalStatus}
    }

    if (getStorageUserRole() === 'Vendedor' || getStorageUserRole() === 'Cadastro') {
      proposalService.fetch(data).then((response) => {
        let data = [];
        setIsLoading(false);
        setProposals(response.data);
        setProposalCount(response.data.length);
        setGetProposalStatus(proposalStatus);
        setGetProposalType(proposalType);
        for(let i = 0, ilen = response.data.length; i < ilen; i++){
          data.push([
            response.data[i]['customer'] ? response.data[i]['customer']['name'] : "",
            response.data[i]['dependents'] ? response.data[i]['dependents'].length : "",
            response.data[i]['plan'] ? response.data[i]['plan']['name'] : "",
            response.data[i]['paymentType'],
            response.data[i]['recurrency'],
            response.data[i]['status'],
            response.data[i]['amount'],
            formatDate(response.data[i]['createdAt']),
            response.data[i]['acceptedDate']
          ])
        }
        setTableData(data);
      },() => {
        setIsLoading(false);
      });
    } else {
      proposalService.fetchAll(data).then((response) => {
        let data = [];
        setIsLoading(false);
        setProposals(response.data);
        setProposalCount(response.data.length);
        setGetProposalStatus(proposalStatus);
        setGetProposalType(proposalType);
        for(let i = 0, ilen = response.data.length; i < ilen; i++){
          data.push([
            response.data[i]['customer'] ? response.data[i]['customer']['name'] : "",
            response.data[i]['dependents'] ? response.data[i]['dependents'].length : "",
            response.data[i]['plan'] ? response.data[i]['plan']['name'] : "",
            response.data[i]['paymentType'],
            response.data[i]['recurrency'],
            response.data[i]['status'],
            response.data[i]['amount'],
            formatDate(response.data[i]['createdAt']),
            response.data[i]['acceptedDate']
          ])
        }
        setTableData(data);
      },() => {
        setIsLoading(false);
      });
    }


  }, [getProposalStatus, getProposalType]);

  React.useEffect(() => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(search);
    let proposalType = urlParams.get('type');
    let proposalStatus = urlParams.get('status');

    if (!proposalStatus) {
      proposalStatus = 'Rascunho';
    }

    if (!proposalType) {
      proposalType = 'Pessoal';
    }

    let data;

    if (dataInicial && dataFinal) {
      data = {proposalType, proposalStatus, dataInicial, dataFinal}
    } else {
      data = {proposalType, proposalStatus}
    }

    if (getStorageUserRole() === 'Vendedor' || getStorageUserRole() === 'Cadastro') {
      proposalService.fetch(data).then((response) => {
        let data = [];
        setIsLoading(false);
        setProposals(response.data);
        setProposalCount(response.data.length);
        setGetProposalStatus(proposalStatus);
        setGetProposalType(proposalType);
        for(let i = 0, ilen = response.data.length; i < ilen; i++){
          data.push([
            response.data[i]['customer'] ? response.data[i]['customer']['name'] : "",
            response.data[i]['dependents'] ? response.data[i]['dependents'].length : "",
            response.data[i]['plan'] ? response.data[i]['plan']['name'] : "",
            response.data[i]['paymentType'],
            response.data[i]['recurrency'],
            response.data[i]['status'],
            response.data[i]['amount'],
            formatDate(response.data[i]['createdAt']),
            response.data[i]['acceptedDate']
          ])
        }
        setTableData(data);
      },() => {
        setIsLoading(false);
      });
    } else {
      proposalService.fetchAll(data).then((response) => {
        let data = [];
        setIsLoading(false);
        setProposals(response.data);
        setProposalCount(response.data.length);
        setGetProposalStatus(proposalStatus);
        setGetProposalType(proposalType);
        for(let i = 0, ilen = response.data.length; i < ilen; i++){
          data.push([
            response.data[i]['customer'] ? response.data[i]['customer']['name'] : "",
            response.data[i]['dependents'] ? response.data[i]['dependents'].length : "",
            response.data[i]['plan'] ? response.data[i]['plan']['name'] : "",
            response.data[i]['paymentType'],
            response.data[i]['recurrency'],
            response.data[i]['status'],
            response.data[i]['amount'],
            formatDate(response.data[i]['createdAt']),
            response.data[i]['acceptedDate']
          ])
        }
        setTableData(data);
      },() => {
        setIsLoading(false);
      });
    }
  }, [dataInicial, dataFinal]);
  
  const rowClickAction = (event, prop, key) => {
    history.push(`${constants.SITE_URL}/admin/create-proposal?id=${proposals[key]._id}`);
  };

  const clearDates = () => {
    setDataInicial('')
    setDataFinal('')
  }
  
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <CustomSelect
        labelText="Status das Propostas Listadas"
        id="selectStatus"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          value: getProposalStatus,
          options: [
            {title: 'Rascunho', value: 'Rascunho'},
            {title: 'Pendente', value: 'Pendente'},
            {title: 'Aprovada', value: 'Aprovada'},
            {title: 'Aguardando', value: 'Aguardando'},
            {title: 'Concluída', value: 'Concluída'},
            {title: 'Todas', value: 'Todas'}
          ],
          onChange: (event) => {
            history.push({
              pathname: '/admin/proposals',
              search: `?status=${event.target.value}&type=${getProposalType}`
            });
            setGetProposalStatus(event.target.value);
          }
        }}
      />
      <CustomSelect
        labelText="Tipo das Propostas Listadas"
        id="selectType"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          value: getProposalType,
          options: [
            {title: 'PF', value: 'Pessoal'},
            {title: 'PJ', value: 'Empresarial'},
          ],
          onChange: (event) => {
            history.push({
              pathname: '/admin/proposals',
              search: `?status=${getProposalStatus}&type=${event.target.value}`
            });
            setGetProposalType(event.target.value);
          }
        }}
      />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{display: 'flex'}}>
        <GridItem xs={2} sm={2} md={2}>
          <p style={{paddingTop: '32px', marginBottom: '0'}}>Filtrar por data:</p>
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <CustomInput
            labelText="Data Inicial"
            id="dt_inicio"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'date',
              value: dataInicial,
              onChange: (event) => {
                setDataInicial(event.target.value);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <CustomInput
            labelText="Data Final"
            id="dt_final"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'date',
              value: dataFinal,
              onChange: (event) => {
                setDataFinal(event.target.value);
              }
            }}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <Button color="primary" onClick={clearDates} style={{marginTop: '40px'}}>Resetar Datas</Button>
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Propostas</h4>
            <p className={classes.cardCategoryWhite}>
              {proposalCount} propostas encontradas
            </p>
          </CardHeader>
          <CardBody>
          {!isLoading && tableData.length === 0 && `Sem resultados`}
          {isLoading && `Carregando lista...`}
          {!isLoading && tableData.length !== 0 && (
            <Table
              tableHeaderColor="primary"
              tableHead={["Titular", "Número de Dependentes", "Plano", "Tipo de Pagamento", "Pagamento", "Status", "Valor", "Data de Criação", "Data de Aceite"]}
              tableData={tableData}
              rowClickAction={rowClickAction}
              hover
            />
          )}
          </CardBody>
          <CardFooter>
            <Link to={`${constants.SITE_URL}/admin/create-proposal`}>
              <Button color="primary" variant="outlined">Adicionar Proposta</Button>
            </Link>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}