import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomCurrencyInput from "components/CustomCurrencyInput/CustomCurrencyInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { useLocation, useHistory } from 'react-router-dom';
import * as constants from "../../constantsuniodonto";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import classNames from "classnames";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ModalImage from "react-modal-image";
import {companyList, planTargetList} from "../../constantsuniodonto";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const finantialResponsibleService = require('../../services/FinantialResponsibleService');
const customerService = require('../../services/CustomerService');
const dependentService = require('../../services/DependentService');
const planService = require('../../services/PlanService');
const proposalService = require('../../services/ProposalService');
const userService = require('../../services/UserService');

const _ = require('lodash');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function ProposalsPage() {

  const {search} = useLocation();

  const [isEdit, setIsEdit] = React.useState(false);
  const [selectedDependent, setSelectedDependent] = React.useState(null);
  const [paymentTypeList, setPaymentTypeList] = React.useState(constants.paymentTypeList);
  const [typeList, setTypeList] = React.useState(constants.planTypeList);
  const [recurrencyList, setRecurrencyList] = React.useState(constants.recurrencyList);
  const [personList, setPersonList] = React.useState(constants.planPersonList);
  const [sexList, setSexList] = React.useState(constants.sexList);
  const [dependentTypeList, setDependentTypeList] = React.useState(constants.dependentTypeList);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [requestMessage, setRequestMessage] = React.useState('');
  const [entityID, setEntityID] = React.useState(null);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = React.useState(false);
  const [proposal, setProposal] = React.useState({
    finantialResponsible: {
      name: '',
      born: '',
      phone: '',
      social_number: '',
      email: '',
      sex: '',
      postalCode: '',
      parentName: '',
      address: '',
      neightborhood: '',
      number: '',
      complement: '',
      city: '',
    },
    isFinantialResponsible: '',
    customer: {
      name: '',
      born: '',
      phone: '',
      social_number: '',
      email: '',
      sex: '',
      postalCode: '',
      parentName: '',
      address: '',
      neightborhood: '',
      number: '',
      complement: '',
      city: '',
    },
    dependents: [],
    plan: {
      name: '',
      code: '',
      description: '',
      obs: '',
      ans: '',
      amount_1_life: '',
      amount_2_life: '',
      amount_3_life: '',
      amount_4_life: '',
      type: '',
      person: '',
      target: '',
      detail_url: '',
      thumb: '',
      company: '',
      startDate: '',
      endDate: ''
    },
    paymentType: '',
    recurrency: '',
    status: '',
    documents: [],
    amount: '',
    paymentUrl: '',
    accountBank: '',
    accountNumber: '',
    accountAgency: '',
    accountNumberDigit: '',
    accountAgencyDigit: '',
    aprovedDate: '',
    acceptedDate: '',
    sellerCode: '',
    externalID: '',
    exported: false,
    user: '',
    createdAt: '',
    obs: '',
    origin: '',
    campaign: ''
  });

  const customerModel = {
    name: '',
    born: '',
    phone: '',
    social_number: '',
    email: '',
    sex: '',
    postalCode: '',
    parentName: '',
    address: '',
    neightborhood: '',
    number: '',
    complement: '',
    city: '',
  };

  const finantialResponsibleModel = {
    name: '',
    born: '',
    phone: '',
    social_number: '',
    email: '',
    sex: '',
    postalCode: '',
    parentName: '',
    address: '',
    neightborhood: '',
    number: '',
    complement: '',
    city: '',
  };

  const classes = useStyles();

  React.useEffect(() => {
    let id;
    if(search.indexOf('id=') !== -1){
      id = search.replace('?id=', '');
      setEntityID(id);
      proposalService.get(id).then((response) => {
        setProposal({...response.data});
        setIsEdit(true);
      }, (error) => {
        setRequestMessage(`Problema recuperar usuário logado`);
        setOpenError(true);
      });
    }
  },[]);

  let history = useHistory();

  const getProposalAmount = () => {
    let amount = 0;
    if (proposal.plan) {
      if (proposal.plan.type === 'Empresarial') {
        if (
          proposal.plan &&
          (!proposal.dependents || proposal.dependents.length === 0)
        ) {
          amount = parseFloat(proposal.plan.amount_1_life);
        } else if (
          proposal.plan &&
          proposal.dependents &&
          proposal.dependents.length === 1
        ) {
          amount =
            parseFloat(proposal.plan.amount_2_life) +
            parseFloat(proposal.plan.amount_1_life);
        } else if (
          proposal.plan &&
          proposal.dependents &&
          proposal.dependents.length === 2
        ) {
          amount =
            parseFloat(proposal.plan.amount_3_life) * 2 +
            parseFloat(proposal.plan.amount_1_life);
        } else if (
          proposal.plan &&
          proposal.dependents &&
          proposal.dependents.length === 3
        ) {
          amount =
            parseFloat(proposal.plan.amount_4_life) * 3 +
            parseFloat(proposal.plan.amount_1_life);
        } else if (
          proposal.plan &&
          proposal.dependents &&
          proposal.dependents.length > 3
        ) {
          amount =
            parseFloat(proposal.plan.amount_4_life) *
            proposal.dependents.length +
            parseFloat(proposal.plan.amount_1_life);
        }
      } else if (proposal.plan.type === 'Pessoal') {
        if (proposal.recurrency === 'Mensal') {
          if (
            proposal.plan &&
            (!proposal.dependents || proposal.dependents.length === 0)
          ) {
            amount = parseFloat(proposal.plan.amount_1_life);
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length === 1
          ) {
            amount = parseFloat(proposal.plan.amount_2_life) * 2;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length === 2
          ) {
            amount = parseFloat(proposal.plan.amount_3_life) * 3;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length === 3
          ) {
            amount = parseFloat(proposal.plan.amount_4_life) * 4;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length > 3
          ) {
            amount =
              parseFloat(proposal.plan.amount_4_life) *
              (proposal.dependents.length + 1);
          }
        } else if (proposal.recurrency === 'Anual') {
          if (
            proposal.plan &&
            (!proposal.dependents || proposal.dependents.length === 0)
          ) {
            amount = parseFloat(proposal.plan.amount_1_life) * 12;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length === 1
          ) {
            amount = parseFloat(proposal.plan.amount_2_life) * 2 * 12;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length === 2
          ) {
            amount = parseFloat(proposal.plan.amount_3_life) * 3 * 12;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length === 3
          ) {
            amount = parseFloat(proposal.plan.amount_4_life) * 4 * 12;
          } else if (
            proposal.plan &&
            proposal.dependents &&
            proposal.dependents.length > 3
          ) {
            amount =
              parseFloat(proposal.plan.amount_4_life) *
              (proposal.dependents.length + 1) *
              12;
          }
        }
      }
    }
    return amount;
  };

  function formatDate(dt) {
    const data = new Date(dt),
    dia  = data.getDate().toString(),
    diaF = (dia.length === 1) ? '0'+dia : dia,
    mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length === 1) ? '0'+mes : mes,
    anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF;
  }

  function handleSaveButton(type, obj){

    let data = {
      ...proposal,
      amount: getProposalAmount()
    }

    if(type){
      switch(type){
        case 'customer':
          data = {
            ...data,
            customer: obj
          }
          break;
        case 'finantialResponsible':
          data = {
            ...data,
            finantialResponsible: obj
          }
          break;
      }
    }

    if(isEdit){
      proposalService.update({...data, user: userService.getStorageUser()}).then((response) => {
        setProposal({...response.data});
        setRequestMessage('Proposta Atualizada com Sucesso');
        setOpenSuccess(true);
      }, (error) => {
        setRequestMessage(`Problema ao atualizar a proposta`);
        setOpenError(true);
      });
    }else{
      proposalService.create({...data}).then((response) => {
        setProposal({...response.data});
        history.push(`${constants.SITE_URL}/admin/create-proposal?id=${response.data._id}`);
        setEntityID(response.data._id);
        setIsEdit(true);
        setOpenSuccess(true);
        setRequestMessage('Proposta Criada com Sucesso');
      }, (error) => {
        setRequestMessage(`Problema ao criar a proposta`);
        setOpenError(true);
      });
    }
  }

  const preventDefault = (event) => event.preventDefault();

  const getTodayDate = () => {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth()+1;
    let yyyy = today.getFullYear();
    if(dd<10)
    {
      dd='0'+dd;
    }

    if(mm<10)
    {
      mm='0'+mm;
    }
    today = dd+'/'+mm+'/'+yyyy;

    return today;
  };

  async function handleAproveButton(){
    handleExportButton()
  }

  function rollbackProposalIntegration(type, customerID = null, finantialResponsibleID = null){
    switch (type) {
      case 'finantialResponsible':
        if (customerID) {
          customerService.remove(customerID);
          setProposal({
            ...proposal,
            customer: {
              ...proposal.customer,
              _id: null
            }
          })
        }
        break;
      case 'dependents':
        if (customerID) {
          customerService.remove(customerID);
          setProposal({
            ...proposal,
            customer: {
              ...proposal.customer,
              _id: null
            }
          })
        }
        if (finantialResponsibleID) {
          finantialResponsibleService.remove(finantialResponsibleID);
          setProposal({
            ...proposal,
            finantialResponsible: {
              ...proposal.finantialResponsible,
              _id: null
            }
          })
        }
        let newDepsList = []
        for(let i = 0, ilen = proposal.dependents.length; i < ilen; i++){
          if(proposal.dependents[i]['_id']){
            dependentService.remove(proposal.dependents[i]['_id']);
          }
          newDepsList.push({
            ...proposal.dependents[i],
            _id: null
          })
        }
        setProposal({
          ...proposal,
          dependents: [...newDepsList]
        })
        break;
    }
  }

  function handleReproveButton(){
    proposalService.update({
      ...proposal,
      amount: getProposalAmount(),
      user: userService.getStorageUser(),
      status: 'Aguardando'
    }).then((response) => {
      setProposal({...response.data,status: 'Aguardando'});
      setRequestMessage('Proposta Reprovada');
      setOpenSuccess(true);
    }, (error) => {
      setRequestMessage('Problema ao reprovar proposta');
      setOpenError(true);
    });
  }

  function handleAnalyseButton(){
    proposalService.update({
      ...proposal,
      amount: getProposalAmount(),
      user: userService.getStorageUser(),
      status: 'Pendente'
    }).then((response) => {
      setProposal({...response.data,status: 'Pendente'});
      setRequestMessage('Proposta salva como Pedente');
      setOpenSuccess(true);
    }, (error) => {
      setRequestMessage('Problema ao mudar status da proposta');
      setOpenError(true);
    });
  }

  function handleExportButton(){
    proposalService.exportProposal({
      ...proposal,
      amount: getProposalAmount()
    }).then((response) => {
      setRequestMessage('Proposta exportada com sucesso');
      setOpenSuccess(true);
      if(response.data.sucesso){
        proposalService.update({
          ...proposal,
          amount: getProposalAmount(),
          status: 'Aprovada',
          exported: true,
          user: userService.getStorageUser(),
          externalID: response.data.dados,
          aprovedDate: getTodayDate()
        }).then((response) => {
          setProposal({...response.data,status: 'Aprovada'});
          setRequestMessage('Proposta Aprovada com sucesso.');
          setOpenSuccess(true);
        }, (error) => {
          setRequestMessage('Problema ao aprovar proposta');
          setOpenError(true);
        });
      }else{
        setRequestMessage('Problema ao exportar proposta');
        setOpenError(true);
      }
    }, (error) => {
      setRequestMessage('Problema ao exportar proposta');
      setOpenError(true);
    });
  }

  function handleConcludeButton(){
    proposalService.update({
      ...proposal,
      user: userService.getStorageUser(),
      status: 'Concluída'
    }).then((response) => {
      setProposal({...response.data,status: 'Concluída'});
      setRequestMessage('Proposta Concluída');
      setOpenSuccess(true);
    }, (error) => {
      setRequestMessage('Problema ao concluir proposta');
      setOpenError(true);
    });
  }

  async function handleIntegrateCustomerButton(){
    if(proposal.customer['_id']){
      return true;
    }
    let creation = {
      obj: false,
      success: false
    };
    let data ={
      ...proposal.customer,
      contractAgreed: false,
      firstAccess: true,
      password: proposal.customer.social_number
    };
    await customerService.create(data).then(async (response) => {
      creation.obj = {...response.data.customer};
      setProposal({
        ...proposal,
        customer: {
          ...response.data.customer
        }
      });
      setOpenSuccess(true);
      setRequestMessage('Cliente Integrado com Sucesso');
      // setTimeout(() => {
      //   handleSaveButton('customer', response.data.customer);
      // },2000);
      creation.success = true;
    }, (error) => {
      setRequestMessage('Para integrar o cliente, preencha todos os campos corretamente');
      setOpenError(true);
      creation.success = false;
    });
    return creation;
  }

  async function handleIntegrateFinantialResponsibleButton(){
    if(proposal.finantialResponsible['_id']){
      return true;
    }
    let creation = {
      obj: false,
      success: false
    };
    await finantialResponsibleService.create(proposal.finantialResponsible).then(async (response) => {
      setProposal({
        ...proposal,
        finantialResponsible: response.data.finantialResponsible
      });
      setOpenSuccess(true);
      setRequestMessage('Responsável Financeiro Integrado com Sucesso');
      // setTimeout(() => {
      //   handleSaveButton('finantialResponsible', response.data.finantialResponsible);
      // },2000);
      creation.success = true;
      creation.obj = {...response.data.finantialResponsible};
    }, (error) => {
      setRequestMessage('Para integrar o responsável financeiro, preencha todos os campos corretamente');
      setOpenError(true);
      creation.success = false;
    });
    return creation;
  }

  async function handleIntegrateDepedentsButton(){
    let isAllOK = true;
    let updatedList;
    for (let i = 0, ilen = proposal.dependents.length; i < ilen; i++){
      updatedList = [...proposal.dependents];
      if(proposal.dependents[i]['_id']){
        continue;
      }
      if(!isAllOK){
        break;
      }
      await dependentService.create(proposal.dependents[i]).then(async (response) => {
        updatedList[i]['_id'] = response.data['_id'];
        setProposal({
          ...proposal,
          dependents: updatedList
        });
        setOpenSuccess(true);
        setRequestMessage(`Dependente ${response.data.name} integrado com Sucesso`);
      }, (error) => {
        setRequestMessage(`Para integrar o dependente ${proposal.dependents[i].name}, preencha todos os campos corretamente`);
        setOpenError(true);
        isAllOK = false;
      });
    }
    return isAllOK;
  }

  function handleDeleteButton(){
    proposalService.remove(entityID).then((response) => {
      history.push(`${constants.SITE_URL}/admin/proposals`);
      setOpenSuccess(true);
      setRequestMessage('Proposta Removida com Sucesso');
      handleCloseRemoveConfirmation();
    }, (error) => {
      setRequestMessage(`Problema ao remover a proposta`);
      setOpenError(true);
      handleCloseRemoveConfirmation();
    });
  }

  const closeAlert = (event, reason) => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleClickOpenRemoveConfirmation = () => {
    //setOpenRemoveConfirmation(true);
    let data = {
      ...proposal,
      amount: getProposalAmount(),
      status: 'Excluída'
    }

    if(isEdit){
      proposalService.update({...data, user: userService.getStorageUser()}).then((response) => {
        setProposal({...response.data});
        setRequestMessage('Proposta Atualizada com Sucesso');
        setOpenSuccess(true);
      }, (error) => {
        setRequestMessage(`Problema ao atualizar a proposta`);
        setOpenError(true);
      });
    }else{
      proposalService.create({...data}).then((response) => {
        setProposal({...response.data});
        history.push(`${constants.SITE_URL}/admin/create-proposal?id=${response.data._id}`);
        setEntityID(response.data._id);
        setIsEdit(true);
        setOpenSuccess(true);
        setRequestMessage('Proposta Criada com Sucesso');
      }, (error) => {
        setRequestMessage(`Problema ao criar a proposta`);
        setOpenError(true);
      });
    }
  };

  const handleRecoverButton = () => {

    let data = {};

    if(proposal.acceptedDate === '' || proposal.acceptedDate === null || proposal.acceptedDate === undefined){
      data = {
        ...proposal,
        amount: getProposalAmount(),
        status: 'Rascunho'
      }
    }else{
      data = {
        ...proposal,
        amount: getProposalAmount(),
        status: 'Pendente'
      }
    }

    if(isEdit){
      proposalService.update({...data, user: userService.getStorageUser()}).then((response) => {
        setProposal({...response.data});
        setRequestMessage('Proposta Atualizada com Sucesso');
        setOpenSuccess(true);
      }, (error) => {
        setRequestMessage(`Problema ao atualizar a proposta`);
        setOpenError(true);
      });
    }else{
      proposalService.create({...data}).then((response) => {
        setProposal({...response.data});
        history.push(`${constants.SITE_URL}/admin/create-proposal?id=${response.data._id}`);
        setEntityID(response.data._id);
        setIsEdit(true);
        setOpenSuccess(true);
        setRequestMessage('Proposta Criada com Sucesso');
      }, (error) => {
        setRequestMessage(`Problema ao criar a proposta`);
        setOpenError(true);
      });
    }
  }

  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Titular</h4>
              <p className={classes.cardCategoryWhite}>Proposta será comercializado pela plataforma</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.name,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.name = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Nascimento"
                    id="born"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.born,
                      type: 'text',
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.born = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Telefone"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.phone,
                      type: 'text',
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.phone = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="CPF"
                    id="social_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.social_number,
                      type: 'text',
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.social_number = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.email,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.email = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSelect
                    labelText="Sexo"
                    id='customerSex'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: `${proposal.customer.sex}`,
                      options: sexList,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.sex = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Nome da Mãe"
                    id="parentName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.parentName,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.parentName = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="CEP"
                    id="postalCode"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.postalCode,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.postalCode = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Logradouro"
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.address,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.address = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Bairro"
                    id="neightborhood"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.neightborhood,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.neightborhood = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Número"
                    id="addressNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.number,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.number = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Complemento"
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.complement,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.complement = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Cidade"
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.customer.city,
                      onChange: (event) => {
                        let cus = {...proposal.customer};
                        cus.city = event.target.value;
                        setProposal({
                          ...proposal,
                          customer: cus,
                        })
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Documentos do Titular</h4>
                </GridItem>
                {proposal.customer.documents && proposal.customer.documents.map((obj, index) => {
                  if (obj.path.split('.').pop() === 'doc') {
                    return (
                      <GridItem xs={12} sm={12} md={3}>
                        <a href={obj.path} target="_blank">
                          {obj.title}
                        </a>
                      </GridItem>
                    )
                  } else if (obj.path.split('.').pop() === 'docx') {
                    return (
                      <GridItem xs={12} sm={12} md={3}>
                        <a href={obj.path} target="_blank">
                          {obj.title}
                        </a>
                      </GridItem>
                    )
                  } else if (obj.path.split('.').pop() === 'pdf') {
                    return (
                      <GridItem xs={12} sm={12} md={3}>
                        <a href={obj.path} target="_blank">
                          {obj.title}
                        </a>
                      </GridItem>
                    )
                  } else {
                    return (
                      <GridItem xs={12} sm={12} md={3}>
                        {obj.title}
                        <ModalImage
                          showRotate={true}
                          small={obj.path}
                          large={obj.path}
                          alt={obj.title}
                        />
                      </GridItem>
                    )
                  }
                })}
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Responsável Financeiro</h4>
              <p className={classes.cardCategoryWhite}>Proposta será comercializado pela plataforma</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomSelect
                    labelText="O titular é o responsável financeiro?"
                    id="sex"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: `${proposal.isFinantialResponsible}`,
                      options: [
                        {title: 'Sim', value: 'Sim'},
                        {title: 'Não', value: 'Não'},
                      ],
                      onChange: (event) => {
                        setProposal({
                          ...proposal,
                          isFinantialResponsible: event.target.value
                        })
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              {proposal.isFinantialResponsible !== 'Sim' && (
                <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Nome"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.name,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.name = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Nascimento"
                      id="born"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.born,
                        type: 'text',
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.born = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Telefone"
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.phone,
                        type: 'text',
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.phone = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="CPF"
                      id="social_number"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.social_number,
                        type: 'text',
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.social_number = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.email,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.email = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Sexo"
                      id="sex"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: `${proposal.finantialResponsible.sex}`,
                        options: sexList,
                        onChange: (event) => {
                          setProposal({
                            ...proposal,
                            finantialResponsible: {
                              ...proposal.finantialResponsible,
                              sex: event.target.value
                            }
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Nome da Mãe"
                      id="parentName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.parentName,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.parentName = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="CEP"
                      id="postalCode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.postalCode,
                        type: 'text',
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.postalCode = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Logradouro"
                      id="address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.address,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.address = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Bairro"
                      id="neightborhood"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.neightborhood,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.neightborhood = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Cidade"
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.city,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.city = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Número"
                      id="addressNumber"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.number,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.number = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Complemento"
                      id="address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.finantialResponsible.complement,
                        onChange: (event) => {
                          let cus = {...proposal.finantialResponsible};
                          cus.complement = event.target.value;
                          setProposal({
                            ...proposal,
                            finantialResponsible: cus,
                          })
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <h4>Documentos do Responsável Financeiro</h4>
                </GridItem>
                {proposal.finantialResponsible.documents && proposal.finantialResponsible.documents.map((obj, index) => {
                  return (
                    <GridItem xs={12} sm={12} md={3}>
                      {obj.title}
                      <ModalImage
                        showRotate={true}
                        small={obj.path}
                        large={obj.path}
                        alt={obj.title}
                      />
                    </GridItem>
                  )
                })}
                </GridContainer>
                </>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados Básicos</h4>
              <p className={classes.cardCategoryWhite}>Dados Básicos</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSelect
                    labelText="Tipo de Pagamento"
                    id="paymentType"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.paymentType,
                      options: paymentTypeList,
                      onChange: (event) => {
                        setProposal({
                          ...proposal,
                          paymentType: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomSelect
                    labelText="Recorrência"
                    id="recurrencyList"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.recurrency,
                      options: recurrencyList,
                      onChange: (event) => {
                        setProposal({
                          ...proposal,
                          recurrency: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Plano"
                    id="plan"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan ?  proposal.plan.name : "",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Valor da Proposta",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: proposal.amount,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      disabled: true
                    }}
                  />
                </GridItem>
                {/*<GridItem xs={12} sm={12} md={12}>*/}
                {/*  <CustomInput*/}
                {/*    labelText="Observação"*/}
                {/*    id="paymentUrl"*/}
                {/*    formControlProps={{*/}
                {/*      fullWidth: true*/}
                {/*    }}*/}
                {/*    inputProps={{*/}
                {/*      value: proposal.paymentUrl,*/}
                {/*      onChange: (event) => {*/}
                {/*        setProposal({*/}
                {/*          ...proposal,*/}
                {/*          paymentUrl: event.target.value,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</GridItem>*/}
                {/*<GridItem xs={12} sm={12} md={4}>*/}
                {/*  <CustomInput*/}
                {/*    labelText="Banco"*/}
                {/*    id="accountBank"*/}
                {/*    formControlProps={{*/}
                {/*      fullWidth: true*/}
                {/*    }}*/}
                {/*    inputProps={{*/}
                {/*      value: proposal.accountBank,*/}
                {/*      onChange: (event) => {*/}
                {/*        setProposal({*/}
                {/*          ...proposal,*/}
                {/*          accountBank: event.target.value,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</GridItem>*/}
                {/*<GridItem xs={12} sm={12} md={4}>*/}
                {/*  <CustomInput*/}
                {/*    labelText="Agência"*/}
                {/*    id="accountAgency"*/}
                {/*    formControlProps={{*/}
                {/*      fullWidth: true*/}
                {/*    }}*/}
                {/*    inputProps={{*/}
                {/*      value: proposal.accountAgency,*/}
                {/*      onChange: (event) => {*/}
                {/*        setProposal({*/}
                {/*          ...proposal,*/}
                {/*          accountAgency: event.target.value,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</GridItem>*/}
                {/*<GridItem xs={12} sm={12} md={4}>*/}
                {/*  <CustomInput*/}
                {/*    labelText="Dígito da Agência"*/}
                {/*    id="accountAgencyDigit"*/}
                {/*    formControlProps={{*/}
                {/*      fullWidth: true*/}
                {/*    }}*/}
                {/*    inputProps={{*/}
                {/*      value: proposal.accountAgencyDigit,*/}
                {/*      onChange: (event) => {*/}
                {/*        setProposal({*/}
                {/*          ...proposal,*/}
                {/*          accountAgencyDigit: event.target.value,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</GridItem>*/}
                {/*<GridItem xs={12} sm={12} md={4}>*/}
                {/*  <CustomInput*/}
                {/*    labelText="Conta"*/}
                {/*    id="accountNumber"*/}
                {/*    formControlProps={{*/}
                {/*      fullWidth: true*/}
                {/*    }}*/}
                {/*    inputProps={{*/}
                {/*      value: proposal.accountNumber,*/}
                {/*      onChange: (event) => {*/}
                {/*        setProposal({*/}
                {/*          ...proposal,*/}
                {/*          accountNumber: event.target.value,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</GridItem>*/}
                {/*<GridItem xs={12} sm={12} md={4}>*/}
                {/*  <CustomInput*/}
                {/*    labelText="Dígito da Conta"*/}
                {/*    id="accountNumberDigit"*/}
                {/*    formControlProps={{*/}
                {/*      fullWidth: true*/}
                {/*    }}*/}
                {/*    inputProps={{*/}
                {/*      value: proposal.accountNumberDigit,*/}
                {/*      onChange: (event) => {*/}
                {/*        setProposal({*/}
                {/*          ...proposal,*/}
                {/*          accountNumberDigit: event.target.value,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</GridItem>*/}
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Data de aceite dos termos"
                    id="acceptedDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.acceptedDate,
                      onChange: (event) => {
                        setProposal({
                          ...proposal,
                          acceptedDate: event.target.value,
                        })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Código do Consultor"
                    id="sellerCode"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.sellerCode,
                      onChange: (event) => {
                        setProposal({
                          ...proposal,
                          sellerCode: event.target.value,
                        })
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Observação"
                    id="obs"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      value: proposal.obs,
                      onChange: (event) => {
                        setProposal({
                          ...proposal,
                          obs: event.target.value
                        });
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Lista de Documentos</h4>
                </GridItem>
                {proposal.documents.map((prop, key) => {
                  if(prop.name === '' || !prop.name){
                    return(
                      <GridItem xs={12} sm={12} md={3}>
                        <Link key={key} target="_blank" href={`${prop.path}`}>
                          Documento sem título
                        </Link>
                      </GridItem>
                    )
                  }else{
                    return(
                      <GridItem xs={12} sm={12} md={3}>
                        <Link key={key} target="_blank" href={`${prop.path}`}>
                          {`${prop.name}`}
                        </Link>
                      </GridItem>
                    )
                  }
                })}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Link da Proposta</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Link target="_blank" href={`https://vendas${(proposal.plan.type === 'Empresarial' && proposal.plan.company && proposal.plan.company === 'ASFAL') ? '' : (window.location.href.includes('-teste') ? '' : 'online')}${window.location.href.includes('-teste') ? '-teste' : ''}.uniodontomaceio.com.br/#/proposal${proposal.plan.type === 'Empresarial' && proposal.plan.company !== 'GENERICO' ? '' : 'Fisical'}?planId=${proposal.plan._id}&proposalId=${proposal._id}`}>
                    Acessar Proposta
                  </Link>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>URL de pagamento</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Link target="_blank" href={proposal.paymentUrl}>
                    Acessar URL de pagamento
                  </Link>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Plano</h4>
              <p className={classes.cardCategoryWhite}>Este é o plano vinculado a esta proposta.</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.name,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Código do Plano"
                    id="code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.code,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="ANS"
                    id="ans"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.ans,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Tipo do plano"
                    id="planTargetList"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.target,
                      options: planTargetList,
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomSelect
                    labelText="Finalidade"
                    id="type"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.type,
                      options: typeList,
                      disabled: true
                    }}
                  />
                </GridItem>
                {proposal.plan.type === 'Pessoal' && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Tipo do cliente"
                      id="person"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.plan.person,
                        options: personList,
                        disabled: true
                      }}
                    />
                  </GridItem>
                )}
                {proposal.plan.type === 'Empresarial' && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                      labelText="Empresa"
                      id="company"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: proposal.plan.company,
                        options: companyList,
                        disabled: true
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="URL da logomarca do plano"
                    id="thumb"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.thumb,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="URL da página de detalhes"
                    id="thumb"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.detail_url,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Descrição"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      value: proposal.plan.description,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Observação"
                    id="obs"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      value: proposal.plan.obs,
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_1_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Titular",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: proposal.plan.amount_1_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_2_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Um dependente",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: proposal.plan.amount_2_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_3_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Dois dependentes",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: proposal.plan.amount_3_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomCurrencyInput
                    id="amount_4_life"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      label: "Três ou mais dependentes",
                      decimalPlaces: 2,
                      variant: "standard",
                      value: proposal.plan.amount_4_life,
                      currencySymbol: "R$",
                      decimalCharacter: ",",
                      digitGroupSeparator: ".",
                      outputFormat: "number",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Início da Divugação"
                    id="startDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.startDate,
                      type: 'date',
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Fim da Divugação"
                    id="endDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: proposal.plan.endDate,
                      type: 'date',
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Gerenciamento de dependentes</h4>
              <p className={classes.cardCategoryWhite}>Gerenciamento de dependentes</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {(proposal.status !== 'Aprovada' && proposal.status !== 'Concluída' && proposal.status !== 'Excluída') && (
                    <Button color="primary" onClick={() => {
                      setProposal({
                        ...proposal,
                        dependents: [...proposal.dependents, {
                          born: null,
                          name: null,
                          parentName: null,
                          sex: null,
                          social_number: null,
                          type: null
                        }]
                      })
                    }}>Adicionar Dependente</Button>
                  )}
                </GridItem>
              </GridContainer>
              {proposal.dependents.map((prop, key) => {
                return (
                  <>
                  <GridContainer key={key}>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Nome"
                        id="dependentsName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: prop.name,
                          onChange: (event) => {
                            let updatedList = [...proposal.dependents];
                            updatedList[key].name = event.target.value;
                            setProposal({
                              ...proposal,
                              dependents: updatedList
                            });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Nascimento"
                        id="dependentsBorn"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: prop.born,
                          type: 'text',
                          onChange: (event) => {
                            let updatedList = [...proposal.dependents];
                            updatedList[key].born = event.target.value;
                            setProposal({
                              ...proposal,
                              dependents: updatedList
                            });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="CPF"
                        id="dependentsSocialNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: prop.social_number,
                          type: 'text',
                          onChange: (event) => {
                            let updatedList = [...proposal.dependents];
                            updatedList[key].social_number = event.target.value;
                            setProposal({
                              ...proposal,
                              dependents: updatedList
                            });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Nome da Mãe"
                        id="dependentsParentName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: prop.parentName,
                          onChange: (event) => {
                            let updatedList = [...proposal.dependents];
                            updatedList[key].parentName = event.target.value;
                            setProposal({
                              ...proposal,
                              dependents: updatedList
                            });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                        labelText="Sexo"
                        id={`dependent${key}_sex`}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: `${prop.sex}`,
                          options: sexList,
                          onChange: (event) => {
                            let updatedList = [...proposal.dependents];
                            updatedList[key].sex = event.target.value;
                            setProposal({
                              ...proposal,
                              dependents: updatedList
                            });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomSelect
                        labelText="Tipo"
                        id={`dependent${key}_type`}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: prop.type,
                          options: dependentTypeList,
                          onChange: (event) => {
                            let updatedList = [...proposal.dependents];
                            updatedList[key].type = event.target.value;
                            setProposal({
                              ...proposal,
                              dependents: updatedList
                            });
                          }
                        }}
                      />
                    </GridItem>
                    {(proposal.status !== 'Aprovada' && proposal.status !== 'Concluída' && proposal.status !== 'Excluída') && (
                      <Button color="danger" onClick={() => {
                        let list = [...proposal.dependents];
                        list.splice(key, 1);
                        setProposal({
                          ...proposal,
                          dependents: list
                        })
                      }}>Excluir Dependente</Button>
                    )}
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4>Documentos do Dependente {key+1}</h4>
                    </GridItem>
                    {prop.documents && prop.documents.map((obj, index) => {
                      return (
                        <GridItem xs={12} sm={12} md={3}>
                          {obj.title}
                          <ModalImage
                            showRotate={true}
                            small={obj.path}
                            large={obj.path}
                            alt={obj.title}
                          />
                        </GridItem>
                      )
                    })}
                  </GridContainer>
                  </>
                )
              })}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardHeader>
                <p>
                  Proposta criada em: {`${formatDate(proposal.createdAt)}`}
                </p>
                <p>
                  Origem: {`${(proposal.origin)}`}
                </p>
                <p>
                  Campanha: {`${(proposal.campaign)}`}
                </p>
                <p>
                  Status Atual: {`${proposal.status}`}
                </p>
                <p>
                  ID Uniodonto: {`${proposal['externalID']}`}
                </p>
                <p>
                  Último a modificar: {`${proposal['user'] ? proposal['user']['name'] : ''}`}
                </p>
              </CardHeader>
            </CardBody>
            <CardFooter>
              {(proposal.status !== 'Aprovada' && proposal.status !== 'Concluída' && proposal.status !== 'Excluída') && (
                <Button color="success" onClick={handleSaveButton}>Salvar Proposta</Button>
              )}
              {isEdit && (proposal.status === 'Pendente' || proposal.status === 'Aguardando') && (
                <Button color="primary" onClick={handleAproveButton}>Aprovar Proposta</Button>
              )}
              {isEdit && (proposal.status === 'Pendente') && (
                <Button color="#ef6c00" onClick={handleReproveButton}>Reprovar Proposta</Button>
              )}
              {isEdit && (proposal.status === 'Aprovada') && (
                <Button disabled={!proposal.exported} color="#0277bd" onClick={handleConcludeButton}>Concluir Proposta</Button>
              )}
              {isEdit && (proposal.status === 'Pendente' || proposal.status === 'Rascunho') && (
                <Button color="danger" onClick={handleClickOpenRemoveConfirmation}>Excluir Proposta</Button>
              )}
              {isEdit && (proposal.status === 'Excluída') && (
                <Button color="danger" onClick={handleRecoverButton}>Recuperar Proposta</Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="success">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={2000} onClose={closeAlert}>
        <Alert severity="error">
          {requestMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openRemoveConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseRemoveConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Alerta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que quer remover esse registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveConfirmation} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteButton} color="danger">
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
